"use client";

import { useRouter } from "@/lib/i18n";
import { useState, useEffect } from "react";

export default function Loading() {
    const router = useRouter();

    const [loading, setLoading] = useState(false);

    // useEffect(() => {
    //     // const handleStart = (url) => url !== router.asPath && setLoading(true);
    //     // const handleComplete = (url) =>
    //     //     url === router.asPath && setLoading(false);
    //     const handleStart = (url) =>
    //         url !== router.pathname && setLoading(true);
    //     const handleComplete = (url) =>
    //         url === router.pathname && setLoading(false);

    //     router.events.on("routeChangeStart", handleStart);
    //     router.events.on("routeChangeComplete", handleComplete);
    //     router.events.on("routeChangeError", handleComplete);

    //     return () => {
    //         router.events.off("routeChangeStart", handleStart);
    //         router.events.off("routeChangeComplete", handleComplete);
    //         router.events.off("routeChangeError", handleComplete);
    //     };
    // });

    return (
        loading && (
            <>
                <div className="fixed h-1 w-full overflow-hidden z-50">
                    <div className="line-loading relative"></div>
                </div>
            </>
        )
    );
}
